import { Component, OnInit } from '@angular/core';
import { ServerSvcService } from '../services/server-svc.service';
import { ProductSvcService } from '../services/product-svc.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-components',
  templateUrl: './all-components.component.html',
  styleUrls: ['./all-components.component.css']
})
export class AllComponentsComponent implements OnInit {

  constructor(public productSvc: ProductSvcService,    private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.productSvc.updateProducts();
  }

  selectProd(prod){
    this.productSvc.selectProduct(prod);
    this.router.navigate(['/details']);
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  pageChanged(i){
    this.productSvc.updateProducts(i)
  }
  nextPage(){
    this.productSvc.updateProducts(Number(this.productSvc.currentPage)+1)
  }
}
