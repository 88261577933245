import { Component, OnInit } from '@angular/core';
import { Assets } from '../assets';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { SearchService } from '../services/search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logoLink: string = Assets.LOGO_LINK;
  faSearch=faSearch

  searchWord: string

  constructor(public searchSvc:SearchService,public router:Router) { }

  ngOnInit(): void {
  }

  async performSearch(){
    await this.searchSvc.performSearch(this.searchWord)
    this.searchWord=""
    this.router.navigate(['/search']);
  }

}
