<div class="body-container">
    <h1>Toate produsele naturiste</h1>
    <div class="product-list-container">
        <div class="product-container shadow p-3 mb-5 rounded" *ngFor="let prod of productSvc.productsToBeDisplayed ">
            <img *ngIf='prod.image==null' src="../../assets/NoImage.png">
            <img *ngIf='prod.image!=null' src="{{prod.image}}">
            <h3>{{prod.name}}</h3>
            <button class="detailsButton" (click)="selectProd(prod)">Detalii produs</button>
        </div>
    </div>
    <div class="pageContainer">
        <div class="pages">
            <a *ngIf="this.productSvc.currentPage!=1" (click)="pageChanged(1)">&lt;&lt;</a>
            <a *ngIf="this.productSvc.currentPage!=1" (click)="pageChanged(this.productSvc.currentPage-1)">&lt;</a>
            <div *ngFor="let item of arrayOne(this.productSvc.numberOfPages); let i = index;">
                <a *ngIf="(i+1)!=this.productSvc.currentPage" (click)="pageChanged(i+1)">{{i+1}}</a>
                <a *ngIf="(i+1)==this.productSvc.currentPage" style="color: black;" (click)="pageChanged(i+1)">{{i+1}}</a>
            </div>
            <a *ngIf="this.productSvc.currentPage!=this.productSvc.numberOfPages" (click)="nextPage()">&gt;</a>
            <a *ngIf="this.productSvc.currentPage!=this.productSvc.numberOfPages"
                (click)="pageChanged(this.productSvc.numberOfPages)">&gt;&gt;</a>
        </div>

    </div>
</div>

