import { Injectable } from '@angular/core';
import { ShoppingCartItem } from '../models/ShoppingCartItem.model';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  items: ShoppingCartItem[]=[]
  
  totalPrice: number=0;

  constructor() { }

  addItemToShoppingCart(prod, quantity){
    let item=new ShoppingCartItem(prod,quantity)
    if(this.items.find(item=>item.product._id==prod._id)!==undefined){
      let insertedItem=this.items.find(item=>item.product._id==prod._id)
      if(Number(insertedItem.requested)+parseInt(quantity)>parseInt(prod.quantity)){
        window.alert("Cantitatea selectata depaseste cantitatea maxima disponibila")
      } else {
        insertedItem.requested=Number(insertedItem.requested)+parseInt(quantity)
      }
    } else {
      this.items.push(item)
    }
  }
  removeItem(item){
    this.items=this.items.filter(p=>p.product._id!==item.product._id)
  }

  updateTotalPrice(){
    this.totalPrice=0;
    this.items.forEach(item=>{
      this.totalPrice=this.totalPrice+item.requested*item.product.price;
    })
  }

  clearShoppingCart(){
    this.items=[]
  }

}
