<div class="container">
    <nav class="navbar">
        <a routerLink="/" routerLinkActive="active">Toate produse</a>
        &nbsp;
        &nbsp;
        <a routerLink="/utilaje" routerLinkActive="active">Utilaje apicole</a>
        &nbsp;
        &nbsp;
        <a routerLink="/naturiste" routerLinkActive="active">Naturiste</a>
        &nbsp;
        &nbsp;
        <a routerLink="/menaj" routerLinkActive="active">Produse menaj</a>
        &nbsp;
        &nbsp;
        <a routerLink="/apicole" routerLinkActive="active">Produse apicole</a>
        &nbsp;
        &nbsp;
        <a routerLink="/cosmetice" routerLinkActive="active">Produse cosmetice</a>
    </nav>
</div>