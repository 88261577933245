import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from '../models/Product.model';
import { Page } from '../helpers/Page';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerSvcService {
  constructor(private http: HttpClient) { }

  getProducts(page=1){
    return this.http.get<Page>(environment.apiEndpoint+'/products'+'?page='+page).toPromise()
  }

  getProductsAfterSearch(searchWord,page=1){
    return this.http.get<Page>(environment.apiEndpoint+'/products/search'+'?page='+page+'&word='+searchWord).toPromise()
  }


  getProductsFiltered(category,page=1){
    return this.http.get<Page>(environment.apiEndpoint+'/products/getFiltered/'+category+'?page='+page).toPromise()
  }

  getProductById(id){
      return this.http.get<Product>(environment.apiEndpoint+'/products/'+id).toPromise()
  }

  async postOrder(order){
    var header = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    const body = JSON.stringify(order);
    await this.http.post(`${environment.apiEndpoint}/orders`, body, { headers: header }).toPromise();
  }

}
