<div class="body-container">
    <div class="header">
        <h3>Detalii Livrare</h3>
    </div>
    <form>
        <div class="input-container">
            <label for="surname">Nume:</label>
            <input name="surname" type="text" [(ngModel)]="orderToBePlaced.surname">
        </div>
        <div class="input-container">
            <label for="name">Prenume:</label>
            <input name="name" type="text" [(ngModel)]="orderToBePlaced.name">
        </div>
        <div class="input-container">
            <label for="email">Email:</label>
            <input name="email"  type="email" size="30" pattern=".+@.+\.[a-z]{2,4}" placeholder="your@email.dom" [(ngModel)]="orderToBePlaced.email">
        </div>
        <div class="input-container">
            <label for="phone">Telefon: +40</label>
            <input name="phone" type="tel" pattern="[0-9]{9}" [(ngModel)]="orderToBePlaced.phone">
        </div>
        <div class="input-container">
            <label for="county">Judet:</label>
            <select name="county" [(ngModel)]="orderToBePlaced.county">
                <option value="" disabled>Judet</option>
                <option value="AB">Alba</option>
                <option value="AG">Arges</option>
                <option value="AR">Arad</option>
                <option value="B" >Bucuresti</option>
                <option value="BC">Bacau</option>
                <option value="BH">Bihor</option>
                <option value="BN">Bistrita</option>
                <option value="BR">Braila</option>
                <option value="BT">Botosani</option>
                <option value="BV">Brasov</option>
                <option value="BZ">Buzau</option>
                <option value="CJ">Cluj</option>
                <option value="CL">Calarasi</option>
                <option value="CS">Caras-Severin</option>
                <option value="CT">Constanta</option>
                <option value="CV">Covasna</option>
                <option value="DB">Dambovita</option>
                <option value="DJ">Dolj</option>
                <option value="GJ">Gorj</option>
                <option value="GL">Galati</option>
                <option value="GR">Giurgiu</option>
                <option value="HD">Hunedoara</option>
                <option value="HG">Harghita</option>
                <option value="IF">Ilfov</option>
                <option value="IL">Ialomita</option>
                <option value="IS">Iasi</option>
                <option value="MH">Mehedinti</option>
                <option value="MM">Maramures</option>
                <option value="MS">Mures</option>
                <option value="NT">Neamt</option>
                <option value="OT">Olt</option>
                <option value="PH">Prahova</option>
                <option value="SB">Sibiu</option>
                <option value="SJ">Salaj</option>
                <option value="SM">Satu-Mare</option>
                <option value="SV">Suceava</option>
                <option value="TL">Tulcea</option>
                <option value="TM">Timis</option>
                <option value="TR">Teleorman</option>
                <option value="VL">Valcea</option>
                <option value="VN">Vrancea</option>
                <option value="VS">Vaslui</option>
            </select>
        </div>
        <div class="input-container">
            <label for="town">Oras:</label>
            <input name="town" type="text" [(ngModel)]="orderToBePlaced.town">
        </div>
        <div class="input-container">
            <label for="address">Adresa:</label>
            <textarea name="address" rows="3" placeholder="Strada, numar etc" [(ngModel)]="orderToBePlaced.address"></textarea>
        </div>
        <div class="input-container">
            <label for="postalCode">Cod postal:</label>
            <input name="postalCode" type="text" pattern="[0-9]{6}" [(ngModel)]="orderToBePlaced.postalCode">
        </div>
        <div class="input-container">
            <label for="details">Alte detalii:</label>
            <textarea name="details" rows="3" [(ngModel)]="orderToBePlaced.details"></textarea>
        </div>
    </form>
    <div class="buttons-container">
        <button (click)="onOrder()" class="detailsButton">Trimite comanda</button>
        <button (click)="closeModal()" class="closeButton">Inchide</button>
    </div>
</div>