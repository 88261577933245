import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetails } from '../models/OrderDetails.model';
import { ServerSvcService } from '../services/server-svc.service';
import { ShoppingCartService } from '../services/shopping-cart.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  orderToBePlaced: OrderDetails

  constructor(public activeModal: NgbActiveModal, public shoppingCartSvc: ShoppingCartService, public serverSvc: ServerSvcService) {
    this.orderToBePlaced  = new OrderDetails("", "", "", "", "", "", "", "", "")
   }

  ngOnInit(): void {
  }

  
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onOrder(){
    // this.serverSvc.postOrder(this.shoppingCartSvc.items)
    // this.shoppingCartSvc.clearShoppingCart()
    console.log(this.orderToBePlaced)
  }

}
