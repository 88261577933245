import { Injectable } from '@angular/core';
import { ServerSvcService } from './server-svc.service';
import { Product } from '../models/Product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductSvcService {

  productsToBeDisplayed: Product[]=[]

  selectedProduct: Product

  currentPage:number

  numberOfPages:number

  constructor(private serverSvc: ServerSvcService) { }

  async updateProducts(pageToBeChanged=1){
    var page=await this.serverSvc.getProducts(pageToBeChanged);
    this.productsToBeDisplayed=page.products;
    this.currentPage=page.currentPage;
    this.numberOfPages=page.totalPages;
  }

  async updateFilteredProducts(category,pageToBeChanged=1){
    var page=await this.serverSvc.getProductsFiltered(category,pageToBeChanged);
    this.productsToBeDisplayed = page.products;
    this.currentPage=page.currentPage;
    this.numberOfPages=page.totalPages;
  }

  async getSelectedProduct(){
    this.selectedProduct=await this.serverSvc.getProductById(localStorage.getItem('prodId'));
  }

  selectProduct(product){
    localStorage.setItem('prodId', product._id);
  }

  async updateProductsAfterSearch(word,pageToBeChanged=1){
    var page=await this.serverSvc.getProductsAfterSearch(word,pageToBeChanged);
    this.productsToBeDisplayed = page.products;
    this.currentPage=page.currentPage;
    this.numberOfPages=page.totalPages;
  }
}
