export class OrderDetails
{
    public surname: String
    public name: String
    public email: String
    public phone: String
    public county: String
    public town: String
    public address: String
    public postalCode: String
    public details: String

    constructor(Surname, Name, Email, Phone, County, Town, Address, PostalCode, Details){
        this.surname = Surname
        this.name = Name
        this.email = Email
        this.phone = Phone
        this.county = County
        this.town = Town
        this.address = Address
        this.postalCode = PostalCode
        this.details = Details
    }
}