import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from  '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AllComponentsComponent } from './all-components/all-components.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { UtilajeApicoleComponent } from './utilaje-apicole/utilaje-apicole.component';
import { NaturisteComponent } from './naturiste/naturiste.component';
import { MenajComponent } from './menaj/menaj.component';
import { ProduseApicoleComponent } from './produse-apicole/produse-apicole.component';
import { CosmeticeComponent } from './cosmetice/cosmetice.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavBarComponent,
    AllComponentsComponent,
    ShoppingCartComponent,
    UtilajeApicoleComponent,
    NaturisteComponent,
    MenajComponent,
    ProduseApicoleComponent,
    CosmeticeComponent,
    ProductDetailsComponent,
    SearchComponent,
    OrderDetailsComponent
],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
