import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllComponentsComponent } from './all-components/all-components.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { UtilajeApicoleComponent } from './utilaje-apicole/utilaje-apicole.component';
import { NaturisteComponent } from './naturiste/naturiste.component';
import { MenajComponent } from './menaj/menaj.component';
import { ProduseApicoleComponent } from './produse-apicole/produse-apicole.component';
import { CosmeticeComponent } from './cosmetice/cosmetice.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { SearchComponent } from './search/search.component';


const routes: Routes = [
  { path: '', component: AllComponentsComponent },
  { path: 'all', component: AllComponentsComponent },
  { path: 'cart', component: ShoppingCartComponent },
  { path: 'utilaje', component: UtilajeApicoleComponent },
  { path: 'naturiste', component: NaturisteComponent },
  { path: 'menaj', component: MenajComponent },
  { path: 'apicole', component: ProduseApicoleComponent },
  { path: 'cosmetice', component: CosmeticeComponent },
  { path: 'details', component: ProductDetailsComponent },
  { path: 'search', component: SearchComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
