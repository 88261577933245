import { Component, OnInit } from '@angular/core';
import { ProductSvcService } from '../services/product-svc.service';
import { SearchService } from '../services/search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor(public productSvc: ProductSvcService,public searchSvc:SearchService,private router: Router) { }

  ngOnInit(): void {
    if(this.productSvc.productsToBeDisplayed.length==0){
      var word=localStorage.getItem('searchWord')
      this.productSvc.updateProductsAfterSearch(word)
    }
  }

  selectProd(prod){
    this.productSvc.selectProduct(prod);
    this.router.navigate(['/details']);
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  pageChanged(i){
    this.productSvc.updateProductsAfterSearch(this.searchSvc.searchWord,i)
  }
  nextPage(){
    this.productSvc.updateProductsAfterSearch(this.searchSvc.searchWord,Number(this.productSvc.currentPage)+1)
  }
}
