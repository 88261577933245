<div class="body-container">
    <h1>Cos de cumparaturi</h1>
    <p *ngIf="this.shoppingCartSvc.items.length==0">Momentan nu aveti nimic in cosul de cumparaturii!</p>
    <table *ngIf="this.shoppingCartSvc.items.length!=0" class="table table-striped">
        <thead>
            <tr>
                <th></th>
                <th scope="col">Nume</th>
                <th scope="col">Cantitate</th>
                <th scope="col">Pret / Produs</th>
                <th scope="col">Pret Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of shoppingCartSvc.items">
                <td>
                    <fa-icon [icon]="faTrash" (click)="removeItem(item)" class="icon"></fa-icon>
                </td>
                <td>{{item.product.name}}</td>
                <td>{{item.requested}}</td>
                <td>{{item.product.price}} lei</td>
                <td>{{item.requested*item.product.price}} lei</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="this.shoppingCartSvc.items.length!=0" class="totalSum">
        <h3 style="color:black">Pretul total este {{shoppingCartSvc.totalPrice}} lei</h3>
        <button class="addToCartButton" (click)="finalizeOder()" style="width: 100%;">Finalizeaza comanda</button>
    </div>
</div>