import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../models/Product.model';
import { ProductSvcService } from '../services/product-svc.service';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ShoppingCartService } from '../services/shopping-cart.service';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  required: number;
  faPlus = faPlus;
  faMinus = faMinus;

  constructor(public productSvc: ProductSvcService, public shoppingSvc: ShoppingCartService) { }

  ngOnInit(): void {
    this.productSvc.getSelectedProduct()
    this.required = 0;
  }

  decreaseRequired() {
    if (!isNaN(this.required)) {
      if (this.required > 0)
        this.required--;
    } else {
      this.required=0;
    }
  }

  increaseRequired() {
    if (!isNaN(this.required)) {
      this.required++;
    } else {
      this.required=1;
    }
  }

  addToShoppingCart(){
    if(isNaN(this.required)){
      window.alert("Cantitatea introdusa trebuie sa fie un numar")
      this.required=0
    } else {
      if(this.required>this.productSvc.selectedProduct.quantity){
       window.alert("Cantitatea ceruta este mai mare decat decat cantitatea maxima disponibila") 
      } else {
        if(this.required>0){
        this.shoppingSvc.addItemToShoppingCart(this.productSvc.selectedProduct,this.required)
        this.required=0;
        }
      }
    }
  }

}
