import { Injectable } from '@angular/core';
import { Product } from '../models/Product.model';
import { ProductSvcService } from './product-svc.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  searchWord: string

  constructor(public productSvc:ProductSvcService) { }

  performSearch(word){
    this.searchWord=word
    localStorage.setItem('searchWord',word);
    this.productSvc.updateProductsAfterSearch(this.searchWord)
  }
}
