import { Component, OnInit } from '@angular/core';
import { Constants } from '../Constants';
import { ProductSvcService } from '../services/product-svc.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-utilaje-apicole',
  templateUrl: './utilaje-apicole.component.html',
  styleUrls: ['./utilaje-apicole.component.css']
})
export class UtilajeApicoleComponent implements OnInit {

  constructor(public productSvc: ProductSvcService,private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.productSvc.updateFilteredProducts(Constants.categories["Utilaje apicole"]);
  }

  selectProd(prod){
    this.productSvc.selectProduct(prod);
    this.router.navigate(['/details']);
  }

  pageChanged(i){
    this.productSvc.updateFilteredProducts(Constants.categories["Utilaje apicole"],i);
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }
  nextPage(){
    this.productSvc.updateFilteredProducts(Constants.categories["Utilaje apicole"],Number(this.productSvc.currentPage)+1)
  }
}
