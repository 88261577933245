import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { faTrash,faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ServerSvcService } from '../services/server-svc.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {OrderDetailsComponent} from '../order-details/order-details.component';


@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  faTrash=faTrash
  faPlus=faPlus
  faMinus=faMinus

  constructor(public shoppingCartSvc:ShoppingCartService, public serverSvc:ServerSvcService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.shoppingCartSvc.updateTotalPrice()
  }

  removeItem(item){
    this.shoppingCartSvc.removeItem(item)
    this.shoppingCartSvc.updateTotalPrice()
  }

  finalizeOder(){
    const modalReference = this.modalService.open(OrderDetailsComponent)
    modalReference.result.then((result) => {
      
    }).catch((error) => {
      console.log(error);
    });
  }

}
