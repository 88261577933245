import { Component, OnInit } from '@angular/core';
import { ProductSvcService } from '../services/product-svc.service';
import { Constants } from '../Constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cosmetice',
  templateUrl: './cosmetice.component.html',
  styleUrls: ['./cosmetice.component.css']
})
export class CosmeticeComponent implements OnInit {

  constructor(public productSvc: ProductSvcService,    private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.productSvc.updateFilteredProducts(Constants.categories["Produse cosmetice"]);
  }

  selectProd(prod){
    this.productSvc.selectProduct(prod);
    this.router.navigate(['/details']);
  }

  pageChanged(i){
    this.productSvc.updateFilteredProducts(Constants.categories["Produse cosmetice"],i);
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }
  nextPage(){
    this.productSvc.updateFilteredProducts(Constants.categories["Produse cosmetice"],Number(this.productSvc.currentPage)+1)
  }
}
