<div class="body-container">
    <div class="details-container">
        <img *ngIf='productSvc.selectedProduct.image==null' src="../../assets/NoImage.png">
        <img *ngIf='productSvc.selectedProduct.image!=null' src="{{productSvc.selectedProduct.image}}">
        <div class="info">
            <h1>{{productSvc.selectedProduct.name }}</h1>
            <br>
            <p><b>Description</b>: {{productSvc.selectedProduct.description }}</p>
            <br>
            <p><b>Cantitate maxim disponibila:</b> {{productSvc.selectedProduct.quantity}}</p>
            <br>
            <p><b>Pret:</b> {{productSvc.selectedProduct.price}} lei</p>
            <br>
            <div class="cantity-selector">
                <fa-icon [icon]="faMinus" (click)="decreaseRequired()" class="icon"></fa-icon><input [(ngModel)]="required"  type="text"><fa-icon [icon]="faPlus" (click)="increaseRequired()" class="icon"></fa-icon>
            </div>
            <br>
            <button class="addToCartButton" (click)="addToShoppingCart()">Adauga in cos</button>
        </div>
    </div>
</div>